@mixin flex(
	$justify-content: center,
	$align-items: center,
	$flex-direction: row
) {
	display: flex;
	justify-content: $justify-content;
	align-items: $align-items;
	flex-direction: $flex-direction;
}
@mixin font(
	$font-size: 24px,
	$font-weight: 400,
	$line-height: 32px,
	$color: #000
) {
	font-size: $font-size;
	font-weight: $font-weight;
	line-height: $line-height;
	color: $color;
}
@mixin desktop-md {
	@media (max-width: 1300px) {
		@content;
	}
}
@mixin desktop {
	@media (min-width: 993px) {
		@content;
	}
}
@mixin tablet {
	@media (max-width: 992px) {
		@content;
	}
}
@mixin mobile {
	@media (max-width: 768px) {
		@content;
	}
}
