/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins Regular'), url('./../../fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins Thin'), url('./../../fonts/Poppins-Thin.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins ExtraLight'),
		url('./../../fonts/Poppins-ExtraLight.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins Light'), url('./../../fonts/Poppins-Light.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins Medium'), url('./../../fonts/Poppins-Medium.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins SemiBold'), url('./../../fonts/Poppins-SemiBold.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins Bold'), url('./../../fonts/Poppins-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins ExtraBold'), url('./../../fonts/Poppins-ExtraBold.woff') format('woff');
}

@font-face {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: normal;
	src: local('Poppins Black'), url('./../../fonts/Poppins-Black.woff') format('woff');
}
