@tailwind base;
@tailwind components;
@tailwind utilities;

@import './base/font';
@import './abstracts/mixins';
@import './abstracts/functions';
@import './abstracts/variables';

@import './base/global';
@import './base/typography';
@import './base/utilities';
