@import 'assets/styles/style.scss';

.ContentContainer {
	margin-top: 69px;
	margin-left: 215px;
	padding: 20px;
	background-color: #fff;
	height: calc(100vh - 69px);
	display: grid;

	@include tablet {
		padding: 0 0 100px;
		margin-top: 75px;
		margin-left: 0;
	}
}
