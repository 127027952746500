@import 'assets/styles/style.scss';
.ModalContainer {
    width: 100%;
    height: 100%;
    z-index: 100000000;
    background-color: rgba(0, 0, 0, 0.4);
    @include flex(center, center, column);
    position: fixed;
    top: 0;
    left: 0;
}