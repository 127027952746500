@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
html,
body,
* {
	font-family: 'Poppins' !important;
	@apply font-medium;
}
body {
	/* width */
	::-webkit-scrollbar {
		width: 3px;
		height: 3px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		@apply bg-gray-200;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		@apply bg-gray-400;
	}
}

.tox-tinymce {
	height: 100% !important;
}
.tox-tinymce-aux,
.tox-dialog-wrap {
	z-index: 9999999999 !important;
}
.tox-statusbar {
	display: none !important;
}

@layer base {
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

input {
	&:focus {
		outline: none !important;
		box-shadow: none !important;
	}
}
#chart {
	.apexcharts-toolbar,
	.apexcharts-tooltip,
	.apexcharts-xaxistooltip-bottom {
		@apply hidden;
	}

	.apexcharts-yaxis,
	.apexcharts-xaxis {
		text {
			font-size: 10px;
			@apply font-light text-gray-400;
		}
	}
}
.team_filter {
	padding: 22px 20px;
	background: #fbfbfb;
	flex: 1;
	margin-right: -11px;

	.filter__head {
		width: 100%;
		.head {
			@include flex(space-between, center);
			margin-bottom: 34px;
			h6 {
				@include font(18px, 500, 27px, #212121);
			}

			button {
				@include font(14px, 400, 21px, #c10000);
			}
		}

		.filter_content {
			margin-top: 30px;
			display: grid;
			gap: 27px;
			> div {
				label {
					@include font(14px, 300, 30px, #212121);
				}

				.css-13cymwt-control,
				.css-t3ipsp-control {
					background: #ffffff;
					border: 1px solid #f3f3f3;
					border-radius: 10px;

					.css-1u9des2-indicatorSeparator {
						display: none;
					}
					.css-1jqq78o-placeholder {
						@include font(12px, 300, 30px, #212121);
						opacity: 0.3;
					}
				}
			}
		}
	}

	> button {
		background: #ffffff;
		border: 1px solid #3484f0;
		border-radius: 10px;
		width: 100%;
		height: 45px;
		@include font(15px, 500, 21px, #3484f0);
		text-align: center;
		&:hover {
			color: #fff;
			background-color: #3484f0;
		}
	}
}
.custom_select_box_error {
	.css-13cymwt-control,
	.css-t3ipsp-control,
	.css-16xfy0z-control {
		border: 1px solid #c10000 !important;
		background-color: rgba($color: #c10000, $alpha: 0.1) !important;
	}
}
.custom_select_box_with_search {
	.css-13cymwt-control,
	.css-t3ipsp-control,
	.css-16xfy0z-control {
		padding-left: 50px !important;
	}
}
.custom_select_box {
	.css-b62m3t-container {
		height: 100%;
	}
	.css-13cymwt-control,
	.css-t3ipsp-control,
	.css-3iigni-container,
	.css-16xfy0z-control {
		background: #ffffff;
		border: 1px solid #f3f3f3;
		border-radius: 10px;
		height: 100%;

		.css-1u9des2-indicatorSeparator,
		.css-894a34-indicatorSeparator {
			display: none;
		}
		.css-1jqq78o-placeholder {
			@include font(12px, 300, 30px, #212121);
			opacity: 0.3;
		}
	}

	.css-16xfy0z-control {
		background: #d8d8d8 !important;
		border: 1px solid #d8d8d8 !important;
		.css-1jqq78o-placeholder {
			color: #000 !important;
		}
		.css-tj5bde-Svg {
			display: none;
		}
	}
}
.custom_select_box.bg_gray {
	.css-13cymwt-control,
	.css-t3ipsp-control {
		background: #fbfbfb !important;
	}
}

.custom_select_box_open_top {
	.css-1nmdiq5-menu {
		bottom: 100% !important;
		top: unset !important;
	}
	.css-1hb7zxy-IndicatorsContainer {
		transform: rotate(180deg);
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
	}
}

.custom_date_picker {
	.rmdp-container {
		height: 100% !important;
		@apply w-full;
		.rmdp-input {
			height: 100% !important;
			padding: 5px 20px;
			border-radius: 10px;
			@apply flex justify-between items-center bg-transparent border-0  w-full  placeholder:text-gray-350 placeholder:text-xs text-xs placeholder:text-opacity-50;
		}
	}
}

.custom_date_picker_left_icon {
	.rmdp-container {
		.rmdp-input {
			padding: 5px 20px 5px 52px;
		}
	}
}

.custom_date_picker_input {
	.rmdp-container {
		@apply w-full;
		.rmdp-input {
			padding: 5px 20px 5px 52px;
			height: 50px;
			@apply flex justify-between items-center bg-gray-300  w-full border border-solid border-gray-200 placeholder:text-gray-350 placeholder:text-xs text-xs placeholder:text-opacity-50;
		}
	}
}
.custom_date_picker_input.bg_white {
	.rmdp-container {
		.rmdp-input {
			background: #fff !important;
		}
	}
}
.custom_date_picker_input_without_icon {
	.rmdp-container {
		.rmdp-input {
			padding: 0 20px !important;
		}
	}
}

.date_picker_in_box {
	@apply relative;
	.rmdp-container {
		height: 100% !important;
		@apply absolute top-0 left-0 w-full;
		.rmdp-input {
			width: 100%;
			height: 100%;
			cursor: pointer;
			opacity: 0;
		}
	}
}

.rdw-editor-wrapper {
	@apply w-full;

	.rdw-editor-toolbar {
		@apply bg-transparent border-none divide-x divide-gray-200 px-2;
	}
	.DraftEditor-root {
		height: 95%;
	}

	.rdw-option-wrapper {
		height: 100%;
		border: none !important;
		background: transparent;
		&:hover {
			box-shadow: none !important;
		}
	}

	.rdw-dropdown-wrapper {
		background: transparent !important;
		border: none !important;
		min-width: 70px;
		&:hover {
			box-shadow: none !important;
		}
	}
	.rdw-editor-main {
		@apply bg-white mt-3 border border-solid border-gray-200 py-6 px-5;
		height: 53vh;
		border-radius: 10px;
		-webkit-border-radius: 10px;
		-moz-border-radius: 10px;
		-ms-border-radius: 10px;
		-o-border-radius: 10px;
	}
	.rdw-option-active {
		box-shadow: none !important;
		background: #939393 !important;
	}
}

button:disabled {
	background: #f3f3f3 !important;
	border-color: transparent !important;
	color: #939393 !important;
}

input {
	&:focus {
		outline: none !important;
		box-shadow: none;
	}
}
.three-dots {
	text-overflow: ellipsis;
	overflow: hidden;
	height: 1.2em;
	white-space: nowrap;
}

#loading-spinner {
	position: relative;
	margin: 0 auto;
	border: 3px solid #f3f3f3;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.Toastify__toast-container {
	z-index: 99999999999999999;
}

.CalendarSliderWithSearch-slider {
	.swiper-slide {
		width: fit-content !important;
	}
}

.date_picker_with_box {
	height: 90px !important;

	@apply top-0 left-0 w-full h-full;
	.rmdp-container {
		height: 100% !important;
		@apply w-full;

		input {
			@apply text-gray-400 font-light text-xs w-full h-full rounded-xl text-center border-2 border-dashed border-gray-400;
		}
	}
	&.error {
		.rmdp-container {
			height: 100% !important;
			@apply w-full;

			input {
				border-color: #c10000 !important;
				background: rgba($color: #c10000, $alpha: 0.1);
			}
		}
	}
}

.tasks_inner_element {
	.infinite-scroll-component__outerdiv {
		height: calc(100% - 55px);
	}
}
