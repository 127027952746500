.task_search_box {
	.css-13cymwt-control,
	.css-t3ipsp-control {
		background: #fbfbfb;
		border: 1px solid #f3f3f3;
		border-radius: 10px;
		padding-left: 56px;
		height: 50px;
		.css-1jqq78o-placeholder {
			font-weight: 300;
			color: rgba($color: #212121, $alpha: 0.3);
			font-size: 12px;
		}

		.css-1hb7zxy-IndicatorsContainer {
			display: none;
		}
	}
}

.task_select_box {
	.rmdp-container {
		@apply w-full;
		.rmdp-input {
			height: 50px;
			padding: 5px 20px;
			border-radius: 10px;
			@apply flex justify-between items-center bg-white  w-full border border-solid border-gray-200 placeholder:text-gray-350 placeholder:text-xs text-xs placeholder:text-opacity-50;
		}
	}
	.custom_range {
		position: relative;

		.rs-range {
			margin-top: 23px;
			width: 100%;
			-webkit-appearance: none;
			&:focus {
				outline: none;
			}
			&::-webkit-slider-runnable-track {
				width: 100%;
				height: 1px;
				cursor: pointer;
				box-shadow: none;
				background: #f3f3f3;
			}
			&::-moz-range-track {
				width: 100%;
				height: 1px;
				cursor: pointer;
				box-shadow: none;
				background: #f3f3f3;
			}

			&::-webkit-slider-thumb {
				height: 18px;
				width: 18px;
				border-radius: 22px;
				background: #3484f0;
				cursor: pointer;
				-webkit-appearance: none;
				margin-top: -9px;
			}
			&::-moz-progress-bar {
				background: #3484f0;
			}
			&::-webkit-progress-bar {
				background: #3484f0;
			}
			&::-moz-range-thumb {
				height: 18px;
				width: 18px;
				border-radius: 22px;
				background: #3484f0;
				cursor: pointer;
				-webkit-appearance: none;
				margin-top: -20px;
			}
			&::-moz-focus-outer {
				border: 0;
			}
			&::-moz-range-progress {
				background: #3484f0 !important;
			}
		}
		.rs-label {
			font-weight: 700;
			font-size: 14px;
			left: 4px;
			line-height: 30px;
			position: absolute;
			text-align: right;
			color: #3484f0;
			bottom: -32px;
		}
	}
}


